<template>
	<Button @click="handleGoogleLogin" :isLoading="isLoading">
		<div class="flex w-1/4 justify-end items-center">
			<img src="/icons/google.svg" alt="Google" class="w-5 h-5" />
		</div>
		<div class="flex w-3/4">Continuar com Google</div>
	</Button>
</template>

<script setup lang="ts">
import { Capacitor } from '@capacitor/core'

const props = defineProps<{
	redirectTo?: string
}>()

const config = useRuntimeConfig()
const route = useRoute()
const { login } = useUserStore()
const { addAlert } = useAlertsStore()
const { $captureEvent, $api } = useNuxtApp()

const GoogleAuth = ref<any>()
const isLoading = ref(false)
const redirectTo = props.redirectTo || (route.query.redirectTo as string)

const handleGoogleLogin = async () => {
	try {
		isLoading.value = true

		if (!Capacitor.isNativePlatform()) {
			$captureEvent('login_google_attempt')

			window.location.href = `${config.public.baseUrl}/api/auth/google${
				redirectTo ? `?redirectTo=${redirectTo}` : ''
			}`
			return
		}

		const response = await GoogleAuth.value.signIn()

		const {
			user,
		}: {
			user: IUser
		} = await $api(`/auth/google/callback?code=${response.serverAuthCode}&skipRedirect=true`)

		login(user)

		const nuxtApp: any = useNuxtApp()
		nuxtApp.$requestPushNotifications()

		$captureEvent('login_google_success')

		if (redirectTo) {
			navigateTo(redirectTo)
			return
		}

		navigateTo(user.personalInfo.document.number ? '/account' : '/account/complete-profile')
		return

		// this.$requestPushNotifications()
	} catch (error) {
		/**
		 * @todo Log error to sentry
		 */
		// this.sentryCaptureException(error)
		console.error(error)

		addAlert({
			variant: 'danger',
			title: 'Falha ao fazer login, por favor tente novamente',
		})

		$captureEvent('login_google_error')
	}

	isLoading.value = false
}

onMounted(() => {
	if (Capacitor.isNativePlatform()) {
		import('@codetrix-studio/capacitor-google-auth').then(({ GoogleAuth: CapacitorGoogleAuth }) => {
			GoogleAuth.value = CapacitorGoogleAuth

			GoogleAuth.value.initialize({
				clientId: config.public.googleWebAppId,
				scopes: ['email', 'profile'],
			})
		})
	}
})
</script>
